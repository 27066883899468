import clsx from 'clsx'
import { Link } from 'react-router-dom'

interface StatsCardProps {
	title: string
	icon?: string
	value: number
	percentage: number
	link: string
	className?: string
}

export const StatsCard = ({ title, icon, percentage, value, link, className }: StatsCardProps) => {
	return (
		<Link
			to={link}
			style={{
				boxShadow: '0px 4px 24px 0px #00000073'
			}}
			className="py-2 px-2 bg-primary md:px-3.5 w-full max-md:min-w-[150px] flex flex-col gap-[15px] rounded-xl border border-[#5A655533]">
			<div className="flex flex-col gap-2">
				<div className="flex items-center justify-between">
					<div className="flex flex-col gap-2">
						{icon && <img src={icon} className="md:size-6 size-4" />}
						<p className="font-medium max-md:hidden text-[#FFFFFFCC] ">{title}</p>
					</div>

					<div className="flex items-center gap-1.5">
						<h1 className="font-domine text-lg md:text-[32px] text-white">{value}</h1>
						<div className="rounded-full bg-[#FFFFFFE5] size-[18px] md:size-6 px-0.5 flex items-center justify-center">
							<p className="font-medium text-primary text-[10px]">{Math.round(percentage)}%</p>
						</div>
					</div>
				</div>
				<p className="font-medium text-xs md:hidden text-[#FFFFFFCC] ">{title}</p>
			</div>

			<div className="overflow-hidden rounded-full bg-[#FFFFFF33]">
				<div style={{ width: `${percentage}%` }} className={clsx('h-2 rounded-full', className)} />
			</div>
		</Link>
	)
}
